import { useEffect, useState } from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Check from '@mui/icons-material/Check';
import Refresh from '@mui/icons-material/Refresh';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { createProject } from 'src/api/projects';
import { Vertical } from 'src/components/Alignments';
import { SectionTitleWithBreadcrumbs } from 'src/components/Section';
import CXRClaim from 'src/configs/claims/CXR';
import DBTClaim from 'src/configs/claims/DBT';
import MMGClaim from 'src/configs/claims/MMG';
import useAlertSnackbar from 'src/hooks/useAlertSnackbar';
import useCheckedParams from 'src/hooks/useCheckedParams';
import useConfirmModal from 'src/hooks/useConfirmModal';
import { newProjectState, useResetNewProject } from 'src/states/newProject';
import { useRefreshProjectList } from 'src/states/project';
import { Modality } from 'src/types/api/data/image';
import { PathNames } from 'src/types/client/url';
import { PROJECTS_PAGE_TITLE } from 'src/utils/constants';
import { UrlUtil } from 'src/utils/url';

import AssetValidations from './AssetValidations';
import Assets from './Assets';
import BasicInformation from './BasicInformation';
import Controls from './Controls';
import FindingFields from './FindingFields';
import FormHeader from './FormHeader/index';
import Modes from './Modes';
import Overrides from './Overrides';
import Overview from './Overview';

const STEPS = [
  'Basic',
  'Modes',
  'Controls',
  'Assets',
  'Asset Validations',
  'Finding Fields',
  'Overrides',
  'Overview',
] as const;

const NewProject = (): JSX.Element => {
  const { modalityLabel } = useCheckedParams<{
    modalityLabel: Modality;
  }>(['modalityLabel']);

  const navigate = useNavigate();
  const { openAlertSnackbar } = useAlertSnackbar();
  const refreshProjectList = useRefreshProjectList();
  const resetNewProject = useResetNewProject();
  const { getConfirmation } = useConfirmModal();

  const newProject = useRecoilValue(newProjectState.current);
  const isValid = useRecoilValue(newProjectState.isValid);
  const setClaim = useSetRecoilState(newProjectState.claim);
  const setModalityLabel = useSetRecoilState(newProjectState.modalityLabel);

  const [currentStep, setCurrentStep] = useState<(typeof STEPS)[number]>(
    STEPS[0]
  );
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    setModalityLabel(modalityLabel);

    switch (modalityLabel) {
      case Modality.CXR:
        setClaim(CXRClaim);
        break;
      case Modality.MMG:
        setClaim(MMGClaim);
        break;
      case Modality.DBT:
        setClaim(DBTClaim);
        break;
      default:
        throw new Error(
          'The currently selected modality does not support project creation.'
        );
    }
  }, [modalityLabel, setClaim, setModalityLabel]);

  const handleClickCreate = async () => {
    try {
      setIsCreating(true);

      await createProject(newProject, modalityLabel);

      openAlertSnackbar({
        severity: 'success',
        description: `"${newProject.name}" is created successfully.`,
      });

      refreshProjectList();
      goToProjectsPage();
      resetNewProject();
    } catch {
      openAlertSnackbar({
        severity: 'error',
        description: 'Failed to create project.',
      });
    }

    setIsCreating(false);
  };

  const handleClickReset = async () => {
    const confirmation = await getConfirmation({
      title: 'Reset the project creation',
      description:
        'This will reset all the changes you made for creating a new project.\nClick confirm if you want to reset all.',
      confirmBtnTestId: 'newProject-resetModal-confirmBtn',
    });

    if (confirmation) {
      resetNewProject();
    }
  };

  const goToProjectsPage = () => {
    navigate(
      `${UrlUtil.getUrl(PathNames.PROJECTS, {
        modalityLabel,
      })}`
    );
  };

  return (
    <StyledVertical>
      <SectionTitleWithBreadcrumbs>
        <Link
          underline="hover"
          color="inherit"
          variant="h5"
          onClick={goToProjectsPage}
          sx={{ cursor: 'pointer' }}
          data-test-id="projectListPageLink"
        >
          {PROJECTS_PAGE_TITLE}
        </Link>
        <Typography
          color="text.primary"
          variant="h5"
          data-test-id={'newProject-pageTitle'}
        >
          New {modalityLabel} Project
        </Typography>
      </SectionTitleWithBreadcrumbs>

      <FormContainer>
        <FormHeader
          items={STEPS}
          currentItem={currentStep}
          setCurrentItem={setCurrentStep}
          resetButtonElement={
            <Button
              startIcon={<Refresh fontSize="small" />}
              color="secondary"
              size="large"
              variant="contained"
              onClick={handleClickReset}
              data-test-id="newProject-resetBtn"
            >
              Reset
            </Button>
          }
          submitButtonElement={
            <LoadingButton
              startIcon={<Check fontSize="small" />}
              color="primary"
              size="large"
              disabled={!isValid}
              onClick={handleClickCreate}
              loading={isCreating}
              variant="contained"
            >
              Confirm
            </LoadingButton>
          }
        />

        <FormBody>
          {currentStep === 'Basic' && <BasicInformation />}
          {currentStep === 'Modes' && <Modes />}
          {currentStep === 'Controls' && <Controls />}
          {currentStep === 'Assets' && (
            <DndProvider backend={HTML5Backend}>
              <Assets />
            </DndProvider>
          )}
          {currentStep === 'Asset Validations' && (
            <DndProvider backend={HTML5Backend}>
              <AssetValidations />
            </DndProvider>
          )}
          {currentStep === 'Finding Fields' && <FindingFields />}
          {currentStep === 'Overrides' && <Overrides />}
          {currentStep === 'Overview' && <Overview />}
        </FormBody>
      </FormContainer>
    </StyledVertical>
  );
};

export default NewProject;

const StyledVertical = styled(Vertical)`
  max-width: 960px;
`;

const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FormBody = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
