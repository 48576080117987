import { useState, useMemo, SyntheticEvent } from 'react';

import { useRecoilValue } from 'recoil';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';

import { updateReviewers } from 'src/api/projects';
import MultiDropdownMenu from 'src/components/Input/MultiDropDownMenu';
import useAlertSnackbar from 'src/hooks/useAlertSnackbar';
import useCheckedParams from 'src/hooks/useCheckedParams';
import { userState } from 'src/states/user';
import { Modality } from 'src/types/api/data/image';
import { SelectOption } from 'src/types/client/ui';
import { Reviewer } from 'src/types/client/user';

import CommonDialog, { CommonDialogProps } from './CommonDialog';

const DropdownContent = styled('div')`
  margin: 16px 0;
`;

interface Props extends CommonDialogProps {
  projectId: string;
  reviewer: Reviewer;
  onUpdate: () => void;
}

export default function UpdateAnnotatorDialog({
  open,
  onClose,
  onUpdate,
  projectId,
  reviewer,
}: Props): JSX.Element {
  const { openAlertSnackbar } = useAlertSnackbar();
  const { modalityLabel } = useCheckedParams<{
    modalityLabel: Modality;
  }>(['modalityLabel']);

  const allAnnotatorsInProject = useRecoilValue(
    userState.annotatorOptionList({
      projectId,
      modality: modalityLabel,
    })
  );

  const allAnnotatorsInProjectExceptCurrentReviewer = useMemo(
    () => allAnnotatorsInProject.filter(opt => opt.value !== reviewer.id),
    [allAnnotatorsInProject, reviewer]
  );

  const initialAssignedAnnotatorsForCurrentReviewer = reviewer.associates.map(
    assoc => {
      const opt = allAnnotatorsInProjectExceptCurrentReviewer.find(
        opt => opt.value === assoc.id
      );
      return { value: assoc.id, label: opt?.label || 'loading...' };
    }
  );

  const [assignedAnnotators, setAssignedAnnotators] = useState<SelectOption[]>(
    initialAssignedAnnotatorsForCurrentReviewer
  );

  const handleSelectAnnotator = (
    event: SyntheticEvent,
    value: SelectOption[] | null
  ) => {
    setAssignedAnnotators(value || []);
  };

  const handleClickAddAnnotators = async () => {
    try {
      await updateReviewers(projectId, modalityLabel, [
        {
          id: reviewer.id,
          associates: assignedAnnotators.map(user => user.value),
        },
      ]);
      openAlertSnackbar({
        severity: 'success',
        description: 'Successfully assigned new annotators',
      });
    } catch {
      openAlertSnackbar({
        severity: 'error',
        description: 'Failed to assign new annotators',
      });
    }
    onUpdate();
  };

  const handleClose = () => {
    if (onClose) onClose();
    setAssignedAnnotators(initialAssignedAnnotatorsForCurrentReviewer);
  };

  return (
    <CommonDialog
      onClose={handleClose}
      title={`Update assigned annotators for reviewer "${reviewer.username}"`}
      open={open}
      fullWidth
    >
      <DialogContent>
        <DropdownContent>
          <MultiDropdownMenu
            label="Assigned annotators"
            options={allAnnotatorsInProjectExceptCurrentReviewer}
            onChange={handleSelectAnnotator}
            value={assignedAnnotators}
          />
        </DropdownContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleClickAddAnnotators}
          disabled={allAnnotatorsInProjectExceptCurrentReviewer.length === 0}
        >
          Update
        </Button>
      </DialogActions>
    </CommonDialog>
  );
}
