import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import orderBy from 'lodash-es/orderBy';
import { useNavigate } from 'react-router-dom';
import { CellProps } from 'react-table';
import { useRecoilValue } from 'recoil';

import Add from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import { SectionTitle } from 'src/components';
import { Vertical } from 'src/components/Alignments';
import ToggleButtons from 'src/components/Button/ToggleButton';
import FilterPanel from 'src/components/FilterPanel';
import TextFilterInput from 'src/components/Input/TextFilterInput';
import Table from 'src/components/Table';
import useCheckedParams from 'src/hooks/useCheckedParams';
import useFilteredAssetTemplates from 'src/hooks/useFilteredAssetTemplates';
import { myModalitiesWithoutDeprecatedCCT } from 'src/states/myInfo';
import { AssetTemplateReadSchema } from 'src/types/api/data/assetTemplate';
import { Modality } from 'src/types/api/data/image';
import { SelectOption } from 'src/types/client/ui';
import { PathNames } from 'src/types/client/url';
import {
  ASSET_TEMPLATES_PAGE_TITLE,
  DATE_FORMAT,
  DEFAULT_PAGE_SIZE,
} from 'src/utils/constants';
import { UrlUtil } from 'src/utils/url';

// Table elements

type SortableColumns = 'name' | 'text' | 'form' | 'group' | 'createdAt';

export default function AssetTemplates(): JSX.Element {
  const navigate = useNavigate();
  const modalityOpts = useRecoilValue(myModalitiesWithoutDeprecatedCCT);
  const { modalityLabel } = useCheckedParams<{
    modalityLabel: Modality;
  }>(['modalityLabel']);

  const modality =
    modalityOpts.find(({ label }) => label === modalityLabel) || null;
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState<string>('-createdAt');
  const [filterBy, setFilterBy] = useState('');
  const { filteredAssetTemplates, isLoading } = useFilteredAssetTemplates(
    filterBy,
    modalityLabel
  );

  const handleChangeModality = (opt: SelectOption) => {
    setPage(1);
    navigate(
      UrlUtil.getUrl(PathNames.ASSET_TEMPLATES, {
        modalityLabel: opt.label,
      })
    );
  };

  const handleClickCreateAssetTemplate = () => {
    navigate(
      `${UrlUtil.getUrl(PathNames.NEW_ASSET_TEMPLATE, {
        modalityLabel,
      })}`
    );
  };

  const handleClickColumnHeader = useCallback(
    (sortOption: SortableColumns) => () => {
      setSortBy(prev =>
        prev === `+${sortOption}` ? `-${sortOption}` : `+${sortOption}`
      );
    },
    []
  );

  const handleClickManageAssetTemplate = useCallback(
    (assetTemplateId: string) => () => {
      navigate(
        `${UrlUtil.getUrl(PathNames.MANAGE_ASSET_TEMPLATE, {
          modalityLabel,
          assetTemplateId,
        })}`
      );
    },
    [modalityLabel, navigate]
  );

  const handleClickDeleteAssetTemplate = useCallback(
    (assetTemplateId: string) => () => {
      console.error(
        `TODO: Open modal(?) to delete Asset Template w id: ${assetTemplateId}`
      );
    },
    []
  );

  const assetTemplateListColumnHeader = useMemo(() => {
    return [
      {
        Header: ASSET_TEMPLATES_PAGE_TITLE,
        columns: [
          {
            Header: 'Asset Template Name',
            accessor: 'name',
            style: { width: '20%' },
            onClickHeader: handleClickColumnHeader('name'),
          },
          {
            Header: 'Text',
            accessor: 'text',
            style: { width: '20%' },
            onClickHeader: handleClickColumnHeader('text'),
          },
          {
            Header: 'Form',
            accessor: 'form',
            onClickHeader: handleClickColumnHeader('form'),
          },
          {
            Header: 'Group',
            accessor: 'group',
            onClickHeader: handleClickColumnHeader('group'),
          },
          {
            Header: 'Creation Date',
            accessor: 'createdAt',
            Cell: function renderDate({
              cell: { value },
            }: CellProps<AssetTemplateReadSchema>) {
              return <div>{dayjs(new Date(value)).format(DATE_FORMAT)}</div>;
            },
            onClickHeader: handleClickColumnHeader('createdAt'),
          },
          {
            Header: 'Action',
            accessor: 'id',
            Cell: function renderManagement({
              cell: { value },
            }: CellProps<AssetTemplateReadSchema>) {
              return (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={handleClickManageAssetTemplate(value)}
                  >
                    Manage
                  </Button>

                  <Button
                    disabled
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={handleClickDeleteAssetTemplate(value)}
                    sx={{ ml: '10px' }}
                  >
                    Delete
                  </Button>
                </>
              );
            },
          },
        ],
      },
    ];
  }, [
    handleClickColumnHeader,
    handleClickDeleteAssetTemplate,
    handleClickManageAssetTemplate,
  ]);

  const handleChangePage = (_: ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterBy(event.target.value);
    setPage(1);
  };

  const assetTemplatesOnCurrentPage = useMemo(() => {
    const sorted = orderBy(
      filteredAssetTemplates,
      sortBy.slice(1),
      sortBy.charAt(0) === '-' ? 'desc' : 'asc'
    );

    return sorted.slice(
      (page - 1) * DEFAULT_PAGE_SIZE,
      page * DEFAULT_PAGE_SIZE
    );
  }, [filteredAssetTemplates, page, sortBy]);

  const totalPages = Math.ceil(
    filteredAssetTemplates.length / DEFAULT_PAGE_SIZE
  );

  return (
    <Vertical>
      <SectionTitle>{ASSET_TEMPLATES_PAGE_TITLE}</SectionTitle>
      <FilterPanel>
        <ToggleButtons
          options={modalityOpts}
          value={modality}
          onChangeLabel={handleChangeModality}
        />
        <Button
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={handleClickCreateAssetTemplate}
          style={{ width: 255 }}
        >
          Create Asset Template
        </Button>
        <TextFilterInput
          size="small"
          filterBy={filterBy}
          onChange={handleFilterChange}
          error={!isLoading && filteredAssetTemplates.length === 0}
        />
      </FilterPanel>
      <Table<AssetTemplateReadSchema>
        columns={assetTemplateListColumnHeader}
        data={assetTemplatesOnCurrentPage}
        sortBy={sortBy}
        isLoading={isLoading}
        paginationProps={{
          page,
          count: totalPages,
          onChange: handleChangePage,
        }}
      />
    </Vertical>
  );
}
