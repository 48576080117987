export enum Modality {
  GENERAL = 'GENERAL',
  CXR = 'CXR',
  MMG = 'MMG',
  // TODO: CCT is fully deprecated but some users still have it as a modality, so this enum value is still required
  DEPRECATED_CCT = 'CCT',
  DBT = 'DBT',
}

enum Location {
  LOCAL = 'LOCAL',
  WEB = 'WEB',
  LUNIT = 'LUNIT',
  AMC = 'AMC',
  SMC = 'SMC',
  SNUH = 'SNUH',
}

interface ImageSchemaBase {
  id: string;
  case: string;
  SOPInstanceUID: string;
  modality: Modality;
  location: Location;
  Rows: number;
  Columns: number;
  view?: string;
  createdAt: string;
  validatedAt?: string;
}

export interface SingleImageSchema extends ImageSchemaBase {
  type: 'single';
  path: string;
}

export interface MultipleImageSchema extends ImageSchemaBase {
  type: 'multiple';
  paths: string[];
}

/**
 * "type" and "paths" fields are added for the DBT usage.
 * This is the merged type for supporting type narrowing.
 *
 * @see https://www.typescriptlang.org/docs/handbook/2/narrowing.html#exhaustiveness-checking
 * @see https://github.com/lunit-io/radiology-data-interface/blob/3.1.0/raddi/types/image.py#L60-L62
 * @see https://lunit.atlassian.net/browse/RAD-2007
 */
export type ImageSchema = SingleImageSchema | MultipleImageSchema;
