import {
  useState,
  useCallback,
  useMemo,
  ChangeEvent,
  useTransition,
} from 'react';

import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { CellProps } from 'react-table';
import {
  useRecoilValue,
  useRecoilValue_TRANSITION_SUPPORT_UNSTABLE,
} from 'recoil';

import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import Button from '@mui/material/Button';

import { SectionTitle } from 'src/components';
import { Vertical } from 'src/components/Alignments';
import ToggleButtons from 'src/components/Button/ToggleButton';
import FilterPanel from 'src/components/FilterPanel';
import CloneProjectDialog from 'src/components/Modal/CloneProjectDialog';
import TransitionTable from 'src/components/TransitionTable';
import useCheckedParams from 'src/hooks/useCheckedParams';
import {
  myInfoState,
  myModalitiesWithoutDeprecatedCCT,
} from 'src/states/myInfo';
import { projectState } from 'src/states/project';
import { Modality } from 'src/types/api/data/image';
import { ClientProject } from 'src/types/client/project';
import { SelectOption } from 'src/types/client/ui';
import { PathNames } from 'src/types/client/url';
import {
  DATE_FORMAT,
  DEFAULT_PAGE_SIZE,
  PROJECTS_PAGE_TITLE,
} from 'src/utils/constants';
import { UrlUtil } from 'src/utils/url';

type SortableColumns = 'name' | 'createdAt';

export default function Projects(): JSX.Element {
  const [isPending, startTransition] = useTransition();
  const { modalityLabel: pathModalityLabel } = useCheckedParams<{
    modalityLabel: Modality;
  }>(['modalityLabel']);

  const navigate = useNavigate();

  const user = useRecoilValue(myInfoState);
  const availableModalitiesForUser = useRecoilValue(
    myModalitiesWithoutDeprecatedCCT
  );

  const modality =
    availableModalitiesForUser.find(
      ({ label }) => label === pathModalityLabel
    ) || null;

  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState<string>('-createdAt');

  const [currentProject, setCurrentProject] = useState<
    | {
        name: string;
        id: string;
      }
    | undefined
  >();

  const { projects, countTotalPages } =
    useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(
      projectState.projectList({
        modality: pathModalityLabel,
        page,
        pageSize: DEFAULT_PAGE_SIZE,
        isAdmin: user.isAdmin,
        sort: sortBy,
      })
    );

  const handleClickColumnHeader = useCallback(
    (sortOption: SortableColumns) => () => {
      startTransition(() => {
        setSortBy(prev =>
          prev === `+${sortOption}` ? `-${sortOption}` : `+${sortOption}`
        );
      });
    },
    []
  );

  const handleCloseCloneProjectDialog = () => {
    setCurrentProject(undefined);
  };

  const handleClickCreateProject = () => {
    navigate(
      `${UrlUtil.getUrl(PathNames.NEW_PROJECT, {
        modalityLabel: pathModalityLabel,
      })}`
    );
  };

  const handleClickManageProject = useCallback(
    (prj: ClientProject) => () => {
      navigate(
        `${UrlUtil.getUrl(PathNames.PROJECT, {
          modalityLabel: pathModalityLabel,
          projectId: prj.id,
        })}`
      );
    },
    [pathModalityLabel, navigate]
  );

  const openCloneProjectModal = useCallback(
    (project: ClientProject) => () => {
      setCurrentProject({
        name: project.name,
        id: project.id,
      });
    },
    []
  );

  const handleChangeModality = (opt: SelectOption) => {
    startTransition(() => {
      setPage(1);
      navigate(
        UrlUtil.getUrl(PathNames.PROJECTS, {
          modalityLabel: opt.label,
        })
      );
    });
  };

  const handleChangePage = (event: ChangeEvent<unknown>, page: number) => {
    if (!modality?.value) {
      return;
    }
    startTransition(() => {
      setPage(page);
    });
  };

  // Table elements
  const projectListColumnHeader = useMemo(() => {
    return [
      {
        Header: 'Project List',
        columns: [
          {
            Header: 'Project Name',
            accessor: 'name',
            style: { width: '40%' },
            onClickHeader: handleClickColumnHeader('name'),
          },
          {
            Header: 'Type',
            accessor: ({ claim: { type } }: ClientProject) =>
              type === 'Standard' ? type : 'CPC',
          },
          {
            Header: 'Creation Date',
            accessor: 'createdAt',
            onClickHeader: handleClickColumnHeader('createdAt'),
            Cell: function renderDate({
              cell: { value },
            }: CellProps<ClientProject>) {
              return <div>{dayjs(new Date(value)).format(DATE_FORMAT)}</div>;
            },
          },
          {
            Header: 'Progress (Done / Total)',
            id: 'progress',
            accessor: ({
              jobStats: {
                annotation: { completedStage, total },
              },
            }: ClientProject) => `${completedStage} / ${total}`,
          },
          {
            Header: 'Confirmed',
            accessor: 'confirmed',
            Cell: function renderConfirmed(cell: { value: boolean }) {
              const { value } = cell;
              return value && <DoneIcon />;
            },
          },
          {
            Header: 'Manage Project',
            id: 'project',
            Cell: function renderManagement(cell: CellProps<ClientProject>) {
              return (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={handleClickManageProject(cell.row.original)}
                >
                  Manage
                </Button>
              );
            },
          },
          {
            Header: 'Clone Project',
            id: 'projectClone',
            Cell: function renderManagement(cell: CellProps<ClientProject>) {
              return (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={openCloneProjectModal(cell.row.original)}
                  data-testid="clone-project-btn"
                >
                  Clone
                </Button>
              );
            },
          },
        ],
      },
    ];
  }, [
    handleClickColumnHeader,
    handleClickManageProject,
    openCloneProjectModal,
  ]);

  return (
    <>
      <Vertical>
        <SectionTitle>{PROJECTS_PAGE_TITLE}</SectionTitle>
        <FilterPanel>
          <ToggleButtons
            options={availableModalitiesForUser}
            value={modality}
            onChangeLabel={handleChangeModality}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={handleClickCreateProject}
            startIcon={<AddIcon />}
            disabled={!modality}
            style={{ minWidth: 200 }}
            data-test-id="createProjectBtn"
          >
            Create {modality?.label} Project
          </Button>
        </FilterPanel>
        <TransitionTable<ClientProject>
          columns={projectListColumnHeader}
          data={projects}
          sortBy={sortBy}
          isPending={isPending}
          paginationProps={{
            page,
            count: countTotalPages,
            onChange: handleChangePage,
          }}
        />
      </Vertical>

      {currentProject && (
        <CloneProjectDialog
          open={true}
          onClose={handleCloseCloneProjectDialog}
          projectName={currentProject.name}
          projectId={currentProject.id}
        />
      )}
    </>
  );
}
