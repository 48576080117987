import { useMemo, useState, useCallback, ChangeEvent, useRef } from 'react';

import { CellProps } from 'react-table';
import { useRecoilValueLoadable } from 'recoil';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import { deleteReviewer } from 'src/api/projects';
import { Flexbox, Vertical } from 'src/components/Alignments';
import AddReviewerDialog from 'src/components/Modal/AddReviewerDialog';
import UpdateAnnotatorDialog from 'src/components/Modal/UpdateAnnotatorDialog';
import Spinner from 'src/components/Spinner';
import Table from 'src/components/Table';
import useAlertSnackbar from 'src/hooks/useAlertSnackbar';
import useCheckedParams from 'src/hooks/useCheckedParams';
import useConfirmModal from 'src/hooks/useConfirmModal';
import { useRefreshReviewerList, userState } from 'src/states/user';
import { Modality } from 'src/types/api/data/image';
import { AnnotatorSchema } from 'src/types/api/data/project';
import { Reviewer, User } from 'src/types/client/user';
import { DEFAULT_PAGE_SIZE } from 'src/utils/constants';
// Interface
interface ReviewersProps {
  projectId: string;
  modality: Modality;
}

export default function Reviewers({ projectId }: ReviewersProps): JSX.Element {
  const { openAlertSnackbar } = useAlertSnackbar();
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const targetReviewer = useRef<Reviewer>();
  const { modalityLabel } = useCheckedParams<{
    modalityLabel: Modality;
  }>(['modalityLabel']);

  const refreshReviewers = useRefreshReviewerList();
  const { contents: reviewerContents, state: reviewerState } =
    useRecoilValueLoadable(
      userState.reviewerList({
        projectId,
        modality: modalityLabel,
      })
    );

  const reviewers = useMemo(
    () => (reviewerState === 'hasValue' ? reviewerContents : []),
    [reviewerContents, reviewerState]
  );

  const { contents: assocContents, state: assocState } = useRecoilValueLoadable(
    userState.associateList({
      idList: Array.from(
        new Set(
          reviewers.flatMap((r: Reviewer) =>
            r.associates.map(assoc => assoc.id)
          )
        )
      ),
    })
  );

  const associates = useMemo(
    () => (assocState === 'hasValue' ? assocContents : []),
    [assocContents, assocState]
  );

  // Pagination
  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(reviewers.length / DEFAULT_PAGE_SIZE);

  const { getConfirmation } = useConfirmModal();

  const handleClickAddReviewer = () => {
    setIsAddDialogOpen(true);
  };

  const handleClickAssign = (reviewer: Reviewer) => () => {
    targetReviewer.current = reviewer;
    setIsUpdateDialogOpen(true);
  };

  const handleChangePage = (event: ChangeEvent<unknown>, page: number) =>
    setPage(page);

  const handleClickDelete = useCallback(
    (reviewer: Reviewer) => async () => {
      const confirm = await getConfirmation({
        title: `Delete Reviewer '${reviewer.username}'`,
        description: `WARNING: If you remove the reviewer '${reviewer.username}' from the project, all of the reviewer ${reviewer.username}'s progress will be deleted. Are you sure you want to delete?`,
      });
      if (confirm) {
        try {
          await deleteReviewer(projectId, modalityLabel, reviewer.id);
          refreshReviewers();
          openAlertSnackbar({
            severity: 'success',
            description: `Successfully deleted a reviewer ${reviewer.username}`,
          });
        } catch {
          openAlertSnackbar({
            severity: 'error',
            description: `Failed to delete a reviewer ${reviewer.username}`,
          });
        }
      }
    },
    [
      getConfirmation,
      modalityLabel,
      openAlertSnackbar,
      projectId,
      refreshReviewers,
    ]
  );

  const handleAddReviewer = () => {
    refreshReviewers();
    setIsAddDialogOpen(false);
  };

  const handleAssignAnnotator = () => {
    refreshReviewers();
    targetReviewer.current = undefined;
    setIsUpdateDialogOpen(false);
  };

  // Table elements
  const reviewerColumn = useMemo(() => {
    return [
      {
        Header: 'Reviewer List',
        columns: [
          {
            Header: 'Reviewer Username',
            accessor: 'username',
          },
          {
            Header: 'Assigned Annotators',
            accessor: 'associates',
            Cell: ({
              cell: { value },
            }: CellProps<Reviewer, AnnotatorSchema[]>) => {
              if (assocState === 'loading') return <Spinner size={26} />;
              return (
                <div>
                  {value?.map(assoc => {
                    const user = associates?.find(
                      (a: User) => a.id === assoc.id
                    );
                    return (
                      <div key={assoc.id}>
                        {user?.username || 'No username'}
                      </div>
                    );
                  }) || ''}
                </div>
              );
            },
          },
          {
            Header: 'Assign Annotators',
            id: 'targetUser',
            style: { width: '10%' },
            Cell: function renderAssignment(
              cell: CellProps<Reviewer, Reviewer>
            ) {
              return (
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={handleClickAssign(cell.row.original)}
                >
                  Assign
                </Button>
              );
            },
          },
          {
            Header: 'Delete Reviewer',
            id: 'deleteReviewer',
            style: { width: '10%' },
            Cell: function renderAssignment(
              cell: CellProps<Reviewer, Reviewer>
            ) {
              return (
                <Button
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={handleClickDelete(cell.row.original)}
                >
                  Delete
                </Button>
              );
            },
          },
        ],
      },
    ];
  }, [assocState, associates, handleClickDelete]);

  const paginatedReviewers: Reviewer[] = useMemo(
    () =>
      reviewers.slice((page - 1) * DEFAULT_PAGE_SIZE, page * DEFAULT_PAGE_SIZE),
    [reviewers, page]
  );

  const handleCloseUpdateAnnotatorDialog = () => {
    targetReviewer.current = undefined;
    setIsUpdateDialogOpen(false);
  };

  return (
    <Vertical>
      <AddReviewerDialog
        projectId={projectId}
        modality={modalityLabel}
        onClose={() => setIsAddDialogOpen(false)}
        onUpdate={handleAddReviewer}
        open={isAddDialogOpen}
      />
      {targetReviewer.current && (
        <UpdateAnnotatorDialog
          open={isUpdateDialogOpen}
          projectId={projectId}
          onClose={handleCloseUpdateAnnotatorDialog}
          onUpdate={handleAssignAnnotator}
          reviewer={targetReviewer.current}
        />
      )}
      <Flexbox>
        <Button
          color="primary"
          onClick={handleClickAddReviewer}
          variant="contained"
          startIcon={<AddIcon />}
          style={{ width: 150 }}
        >
          Reviewers
        </Button>
      </Flexbox>
      <Table<Reviewer>
        columns={reviewerColumn}
        data={paginatedReviewers}
        isLoading={reviewerState === 'loading'}
        isError={reviewerState === 'hasError'}
        onRefresh={refreshReviewers}
        paginationProps={{
          page,
          count: totalPages,
          onChange: handleChangePage,
        }}
      />
    </Vertical>
  );
}
